<template>
    <div>
        <div v-if="showPrint" class="w-100 d-flex justify-content-center">
            <vs-button color="#02913f" relief @click="generateImage">
                <span class="py-1"> IMÁGEN </span>
            </vs-button>
            <vs-button color="#f07325" relief @click="generatePdf">
                <span class="py-1 mx-3"> PDF </span>
            </vs-button>
            <slot name="buttons"></slot>
        </div>
        <div v-show="show" class="mt-3 w-100 d-flex justify-content-center">
            <div
                id="order-invoice"
                ref="orderTemplate"
                class="w-ticket bg-white text-dark py-3"
            >
                <div class="d-flex flex-column align-items-center">
                    <img
                        src="/img/logos/complete_logo.png"
                        width="80"
                        alt="Logo"
                    />
                    <div
                        class="text-center d-flex flex-column align-items-center justify-content-center"
                    >
                        <div v-if="order.store" class="d-flex flex-column">
                            <span>
                                {{ order.store.name }}
                            </span>
                            <span>
                                {{ order.store.address }}
                            </span>
                        </div>

                        <small v-else>{{ $marketplaceUrl }}</small>
                        <small>
                            {{ order.createdAt | date }}
                        </small>
                    </div>
                </div>

                <div class="mb-3 pl-2">
                    <div
                        v-if="order.customer && order.customer.firstName"
                        class="d-flex align-items-center w-100"
                    >
                        <span class="font-weight-bold">Cliente:&nbsp;</span>
                        <small class="text-capitalize">
                            {{ order.customer.firstName }}&nbsp;{{
                                order.customer.lastName
                            }}
                        </small>
                    </div>
                    <div
                        v-if="
                            order.paymentStatus ===
                            $data.$paymentStatuses.approved
                        "
                        class="d-flex align-items-center w-100"
                    >
                        <span class="font-weight-bold">
                            Método de pago:&nbsp;
                        </span>
                        <small>{{ paymentMethod }}</small>
                    </div>

                    <div
                        v-if="order.valuePaid < order.total"
                        class="d-flex align-items-center w-100"
                    >
                        <span class="font-weight-bold"> Abono:&nbsp; </span>
                        <small>
                            {{ order.valuePaid | currency }}
                            {{ paymentMethod }} | Saldo
                            {{ (order.total - order.valuePaid) | currency }}
                        </small>
                    </div>
                    <div class="d-flex align-items-center w-100">
                        <span class="font-weight-bold">
                            Fecha de entrega:&nbsp;
                        </span>
                        <small>{{ order.deliveryAt | dateSimple }}</small>
                    </div>
                    <div
                        v-if="order.pickupMerchant && order.pickupMerchant.name"
                        class="d-flex align-items-center w-100"
                    >
                        <span class="font-weight-bold"> Punto:&nbsp; </span>
                        <small>
                            {{ order.pickupMerchant.name }} |
                            {{ order.pickupMerchant.address }}
                        </small>
                    </div>
                    <div
                        v-if="order.location && order.location.address"
                        class="d-flex align-items-center w-100"
                    >
                        <span class="font-weight-bold"> Domicilio:&nbsp; </span>
                        <small>
                            {{ order.location.address }}
                            {{ order.location.addressComplement }}
                        </small>
                    </div>
                </div>

                <table class="table print border-bottom border-2">
                    <thead>
                        <tr>
                            <th scope="col-5">
                                <span class="font-weight-bold text-sm">
                                    Producto
                                </span>
                            </th>
                            <th scope="col-1">
                                <span class="font-weight-bold text-sm">
                                    Uds.
                                </span>
                            </th>
                            <th scope="col-3">
                                <span class="font-weight-bold text-sm">
                                    $Ud.
                                </span>
                            </th>

                            <th scope="col-3">
                                <span class="font-weight-bold text-sm">
                                    Total
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in references" :key="index">
                            <td class="col-5 pr-0">
                                <span class="text-sm">
                                    {{ item.product.name | cut(36) }}<br />
                                    #{{ item.id }} | T {{ item.size }}
                                </span>
                            </td>
                            <th scope="col-1 text-center">
                                <span class="text-sm">
                                    {{ item.quantity }}
                                </span>
                            </th>
                            <td class="col-3 text-center">
                                <small>
                                    {{
                                        (item.priceOffer -
                                            item.appliedDiscountPerReference ||
                                            0) | currency(true, true)
                                    }}
                                </small>
                            </td>
                            <td class="col-3">
                                <small>
                                    {{
                                        ((item.priceOffer -
                                            item.appliedDiscountPerReference ||
                                            0) *
                                            item.quantity)
                                            | currency(true, true)
                                    }}
                                </small>
                            </td>
                        </tr>
                        <tr>
                            <th colspan="1" scope="colgroup">
                                <div
                                    class="h-100 d-flex justify-content-center align-items-end"
                                >
                                    <span class="font-weight-bold h3 m-0">
                                        Total
                                    </span>
                                </div>
                            </th>
                            <th colspan="3" scope="colgroup">
                                <div
                                    class="h-100 d-flex justify-content-center align-items-end"
                                >
                                    <span class="h4 m-0">
                                        {{ order.total | currency(true, true) }}
                                    </span>
                                </div>
                            </th>
                        </tr>
                    </tbody>
                </table>

                <div
                    class="mt-3 d-flex flex-column justify-content-center align-items-center"
                >
                    <barcode-viewer :input="order.uuid" />
                    <small> #{{ order.id }} - {{ order.uuid }} </small>
                    <p class="text-center d-flex justify-content-center">
                        ¡GRACIAS POR TU COMPRA !
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { deliveryOptions } from "@/common/fields/delivery-options";
import { deliveryStatuses } from "@/common/fields/delivery-status-codes";
import { paymentMethodsAbbreviation } from "@/common/fields/payment-methods";
import { paymentStatuses } from "@/common/fields/payment-status-codes";
import { downLoadImage, elementToImage, openImage } from "@/common/lib/images";
import { downLoadPDF, elementToPDF, openPDF } from "@/common/lib/pdf";

import BarcodeViewer from "@/components/utils/BarcodeViewer.vue";

export default {
    name: "OrderPrinter",
    components: { BarcodeViewer },
    props: {
        show: {
            default: () => false,
            required: false,
            type: Boolean
        },
        order: {
            default: () => ({
                id: 0,
                uuid: "12123131",
                paymentMethod: "cashonpickup",
                paymentStatus: "pya",
                customerName: "",
                createdAt: "2022-07-15T02:39:03.833Z",
                total: 10,
                references: [],
                anonymousReferences: []
            }),
            required: false,
            type: Object
        },
        showPrint: {
            default: () => false,
            required: false,
            type: Boolean
        }
    },
    data: () => ({
        paymentMethod: paymentMethodsAbbreviation.cashondelivery,
        references: [],
        $paymentStatuses: paymentStatuses,
        $deliveryStatuses: deliveryStatuses,
        $deliveryOptions: deliveryOptions
    }),
    watch: {
        order(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.order);
    },
    methods: {
        async generateImage() {
            const imgData = await elementToImage(
                document.getElementById("order-invoice")
            );
            if (!this.$isPhone) {
                downLoadImage(imgData.image, `order_${this.order.id}`);
            } else {
                openImage(imgData, `Orden ${this.order.id}`);
            }
        },
        async generatePdf() {
            const pdf = await elementToPDF(
                document.getElementById("order-invoice")
            );
            if (this.$isPhone) {
                downLoadPDF(pdf, `order_${this.order.id}.pdf`);
            } else {
                openPDF(pdf);
            }
        },
        setInput(value) {
            if (value.paymentStatus === paymentStatuses.approved) {
                this.paymentMethod =
                    paymentMethodsAbbreviation[value.paymentMethod];
            }
            this.references = [
                ...value.references,
                ...value.anonymousReferences
            ].filter((item) => item.quantity);
            if (!this.showPrint) {
                this.generateImage();
            }
        }
    }
};
</script>
